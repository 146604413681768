@font-face {
  font-family: 'Raleway';
  src: local('Raleway'),
    url(./assets/fonts/Raleway/static/Raleway-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'),
    url(./assets/fonts/Raleway/static/Raleway-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'),
    url(./assets/fonts/Raleway/static/Raleway-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-background {
    	background: linear-gradient(to right, #FFFFFF 70%, #F0F5F5 30%);
    }

.form-control:focus {
  box-shadow: 0 0 0 1px #161416;
}

.read.form-control:focus {
  box-shadow: 0 0 0 1px #ebebeb;
}

.icon_down_dir:before {
  content: ""
}

.searchWrapper {
    border: none;
    border-radius: 0px;
    min-height: 0px;
    max-height: 60px;
    padding: 0px;
    position: initial;
}

.multiSelectContainer li:hover{
  background: #161616;
  color: #FFFFFF;
}

.chip {
background: #161616;
}

.tooltip .tooltip-text {
  visibility: hidden;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  z-index: 100;
  background-color: #161616;
  color: #FFFFFF;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}

.active-li {
  background: #5DDDE7;
  border-radius: 9999px;
  width: 28px;
  height: 28px;
  text-align: center;
  margin-top: 8px;
}

.accordion-button:not(.collapsed) {
 color: #161616;
 box-shadow: none;
}

.form-check-input:checked {
  background-color: #161616;
  border-color: #161616;
}

.nav-tabs .nav-link {
  color: #161616;
}

.nav-tabs .nav-link.active {
  color: #161616;
  border-color: #0FDAEA;
}

.transaction.accordion-button::before {
  flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0px;
    margin-right: 10px;
    content: "";
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
}

.transaction.accordion-button::after {
  background-image: none;
}

.accordion-collapse.collapse {
  visibility: inherit;
}

.transaction.accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: none;
}

.transaction.accordion-button:not(.collapsed)::before {
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%232563eb%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
    transform: rotate(-180deg);
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #5DDDE7;
}

.journey.accordion-button::after {
  background-image: url('assets/plus-icon.svg');
}

.journey.accordion-button:not(.collapsed)::after {
    background-image: url('assets/minus-icon.svg');
}

.emoji-picker-react {
  right: 240px;
}

@layer components {
  .label-b-sm-neutral-1000 {
    @apply flex flex-col m-2 px-2 font-bold text-sm text-neutral-1000;
  }

  .select-n-lg-gray-500 {
    @apply form-select appearance-none form-control block w-full px-4 py-2 text-lg text-gray-500 bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 
    focus:text-gray-500 focus:border-gray-500 focus:outline-none placeholder-gray-800 bg-no-repeat;
  }

  .label-b-sm-neutral-1000-row {
    @apply flex flex-row m-2 px-2 font-bold text-sm text-neutral-1000 space-x-2;
  }

  .label-m-md-gray-500 {
    @apply form-check-label text-md font-medium text-gray-500;
  }

  .label-n-lg-gray-200-row {
    @apply form-control block px-4 py-2 text-lg w-full text-gray-200 bg-clip-padding border 
    border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 
    focus:border-gray-500 focus:outline-none placeholder-gray-800;
  }

  .h3-m-xl-neutral-1000 {
    @apply text-xl font-medium text-neutral-1000;
  }

  .h3-b-sm-neutral-1000 {
    @apply text-sm font-bold text-neutral-1000 mt-4;
  }

  .h3-m-mdl-gray-500 {
    @apply text-mdl font-medium text-gray-500 mt-2;
  }

  .p-n-sm-gray-500 {
    @apply text-sm text-gray-500;
  }

  .textarea-n-mdl-gray-500 {
    @apply form-control block w-full md:w-5/12 px-3 py-1.5 text-mdl text-gray-500 
    bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out m-0  
    focus:outline-none;
  }

  .radio-n-xl-gray-300 {
    @apply form-check-input inline-block appearance-none h-4 w-4 border border-gray-300 rounded-sm 
    checked:bg-gray-500 checked:border-gray-500 focus:outline-none transition duration-200 mt-1 align-top 
    bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer;
  }

  .submit-b-lg-neutral-000 {
    @apply inline-block px-6 py-2.5 bg-gray-500 text-neutral-000 font-bold text-lg leading-tight rounded-full mr-2.5;
  }

  .cancel-b-lg-gray-500 {
    @apply inline-block px-6 py-2 border-2 border-gray-500 text-gray-500 font-bold text-lg leading-tight rounded-full;
  }

  .dropzone-n-lg-gray-50 {
  @apply flex flex-col justify-center items-center w-full h-48 rounded-lg border-2 border-gray-300 
  cursor-pointer hover:bg-gray-100 dark:hover:border-gray-500;
  }

  .load-more {
  @apply inline-block px-6 py-2 border-2 border-gray-200 text-gray-000 font-bold text-lg leading-tight rounded-full;
  }  
  
  .asset-cell {
  @apply form-control block w-20 px-4 py-2 text-lg text-gray-200 bg-clip-padding border border-solid rounded-full border-gray-300 transition ease-in-out m-0 focus:text-gray-500 focus:border-gray-500 focus:outline-none placeholder-gray-800;
  }

  .nav-tabs-link {
    @apply nav-link
      block
      leading-tight
      border-x-0 border-t-0 border-b-2 border-transparent
      px-4
      py-3
      mt-2 mx-4
      hover:border-transparent hover:bg-gray-100
      focus:border-transparent
      font-bold text-gray-500 text-lg;
  }
}